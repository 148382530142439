<template>
  <section class="hero is-fullheight p-0 m-0">
    <div
      class="columns is-vcentered"
      style="height: 100vh"
    >
      <div
        class="column is-half is-hidden-mobile"
        style="background-image: url(/img/cover.jpg); background-repeat: no-repeat; background-size: cover; background-position: left; height: 102vh;"
      />
      <div class="column is-half">
        <router-view />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FullPageTwo',
  created () {
    this.$store.dispatch('toggleFullPage', true)
  },
  beforeDestroy () {
    this.$store.dispatch('toggleFullPage', false)
  }
}
</script>
